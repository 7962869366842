<template>
  <div>
    <shop-head currentPageName="REGISTER"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Register"></breadcrumb>
    <div class="container register-box">
      <el-row type="flex" justify="center">
        <el-col :md="12">
          <div class="register-form">
            <div class="register-title">Register</div>
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Country" prop="country">
                    <el-input v-model="form.country" placeholder="Country"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Name or company" prop="name">
                    <el-input v-model="form.name" placeholder="You name or company"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Email Address" prop="emailAddress">
                <el-input v-model="form.emailAddress" placeholder="Email Address"></el-input>
              </el-form-item>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Password" prop="password">
                    <el-input v-model="form.password" type="password" placeholder="Password"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Confirm Password" prop="confirmPassword">
                    <el-input v-model="form.confirmPassword" type="password" placeholder="Confirm Password"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="WhatsApp" prop="whatsApp">
                    <el-input v-model="form.whatsApp" placeholder="WhatsApp"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <div @click="doRegister" class="register-btn">REGISTER</div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from '../../components/shop/head'
import commonFooter from '../../components/common/footer'
import breadcrumb from '../../components/common/breadcrumb'
export default {
  components: {
    shopHead,
    commonFooter,
    breadcrumb
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if(value === '') {
        callback(new Error('Please enter your password again!'));
      }else if(value !== this.form.password) {
        callback(new Error('The passwords entered twice do not match!'));
      }else {
        callback();
      }
    }
    return {
      isRemember: false,
      form: {
        country: '',
        name: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
        whatsApp: ''
      },
      rules: {
        country: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        emailAddress: [{ type: 'email', required: true, message: 'Please enter the correct email address', trigger: 'blur' }],
        password: [{ required: true, message: ' ', trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    /** 登录 */
    doRegister() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post('/foreign/login/registerByHw',{
            email: this.form.emailAddress,
            password: this.form.password,
            tempCompanyName: this.form.name,
            country: this.form.country,
            whatsApp: this.form.whatsApp
          }).then(res => {
            if(res.successful){
              this.$message.success('Please wait for review');
              let timer = setTimeout(() => {
                clearTimeout(timer);
                this.$router.push({
                  path: '/login'
                })
              },1500);
            }else{
              this.$message.error(res.tips);
            }
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.register-box {
  padding: 60px 15px;
  .register-form {
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 1px 1px 5px 5px rgb(0, 0, 0, 0.1);
    .register-title {
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }
    ::v-deep .el-form-item__label{
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 12px;
      padding-bottom: 0;
    }
    ::v-deep .el-input__inner{
      border-radius: 0;
      &:focus {
        border-color: #fed100;
      }
    }
  }
  .register-btn {
    width: 140px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    display: block;
    margin-top: 36px;
    border-radius: 0;
    border: 0;
    text-transform: uppercase;
    background-color: #333333;
    cursor: pointer;
    &:hover {
      background-color: #fed100;
    }
  }
}
</style>